import { EcommerceItem, dispatchAddToCartToAnalytics, dispatchAddToWishlist } from '../analytics';
import { CartAndWishlistReturnObject, CartItemObject, WishlistItemObject } from '../interfaces/components';
import { postCartMessageToIframe } from './cart';
import { postWishlistMessageToIframe } from './wishlist';

export const moveToWishlist = (ids: string[]): CartAndWishlistReturnObject => {
  const wishlistJSON = localStorage.getItem('app_wm_wishlist') || '[]';
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';

  const cart: CartItemObject[] = JSON.parse(cartJSON);
  const wishlist: WishlistItemObject[] = JSON.parse(wishlistJSON);

  const itemsToMove = cart.filter((cartItem: CartItemObject) => ids.includes(cartItem.id));

  if (itemsToMove.length > 0) {
    const _newCart = cart.filter((cartItem: CartItemObject) => !ids.includes(cartItem.id));
    localStorage.setItem('app_wm_cart', JSON.stringify(_newCart));

    const newWishlistItems: WishlistItemObject[] = itemsToMove
      .filter((item) => !wishlist.some((wishlistItem) => wishlistItem.id === item.id))
      .map((item) => ({
        id: item.id,
        name: item.name,
        imageSrc: item.imageSrc,
        price: item.price,
        quantity: item.quantity,
        inStock: item.inStock,
        selectedVariantAttributes: item.selectedVariantAttributes,
      }));

    const _newWishlist = [...wishlist, ...newWishlistItems];
    localStorage.setItem('app_wm_wishlist', JSON.stringify(_newWishlist));
    addToWishlistAnalytic(newWishlistItems);

    return { cartItems: _newCart, wishListItems: _newWishlist };
  } else {
    return { cartItems: cart, wishListItems: wishlist };
  }
};

export const moveToCart = (ids: string[]): CartAndWishlistReturnObject => {
  const wishlistJSON = localStorage.getItem('app_wm_wishlist') || '[]';
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';

  const cart: CartItemObject[] = JSON.parse(cartJSON);
  const wishlist: WishlistItemObject[] = JSON.parse(wishlistJSON);

  const itemsToMove = wishlist.filter((wishlistItem: WishlistItemObject) => ids.includes(wishlistItem.id));

  if (itemsToMove.length > 0) {
    const _newWishlist = wishlist.filter((wishlistItem: WishlistItemObject) => !ids.includes(wishlistItem.id));
    localStorage.setItem('app_wm_wishlist', JSON.stringify(_newWishlist));

    const newCartItems: CartItemObject[] = itemsToMove
      .filter((item) => !cart.some((cartItem) => cartItem.id === item.id))
      .map((item) => ({
        id: item.id,
        name: item.name,
        imageSrc: item.imageSrc,
        price: item.price,
        quantity: item.quantity,
        inStock: item.inStock,
        selectedVariantAttributes: item.selectedVariantAttributes,
      }));

    const _newCart = [...cart, ...newCartItems];
    localStorage.setItem('app_wm_cart', JSON.stringify(_newCart));
    addToCartAnalytic(newCartItems);

    return { cartItems: _newCart, wishListItems: _newWishlist };
  } else {
    return { cartItems: cart, wishListItems: wishlist };
  }
};

export const copyItemFromWishlistToCart = (id: string): CartItemObject[] => {
  const wishlistJSON = localStorage.getItem('app_wm_wishlist') || '[]';
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';

  const cart: CartItemObject[] = JSON.parse(cartJSON);
  const wishlist: WishlistItemObject[] = JSON.parse(wishlistJSON);

  const itemToCopy = wishlist.find((wishlistItem: WishlistItemObject) => wishlistItem.id === id);

  if (itemToCopy && itemToCopy.id.length > 0) {
    // Check if the item already exists in the cart
    const isItemInCart = cart.some((cartItem) => cartItem.id === id);

    if (!isItemInCart) {
      const newCartItem: CartItemObject = {
        id: itemToCopy.id,
        name: itemToCopy.name,
        imageSrc: itemToCopy.imageSrc,
        price: itemToCopy.price,
        quantity: itemToCopy.quantity,
        inStock: itemToCopy.inStock,
        selectedVariantAttributes: itemToCopy.selectedVariantAttributes,
      };

      const _newCart = [...cart, newCartItem];
      localStorage.setItem('app_wm_cart', JSON.stringify(_newCart));
      addToCartAnalytic([newCartItem]);

      return _newCart;
    }
  }

  return cart;
};

export const copyMultipleItemsFromWishlistToCart = (ids: string[]): CartItemObject[] => {
  const wishlistJSON = localStorage.getItem('app_wm_wishlist') || '[]';
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';

  const cart: CartItemObject[] = JSON.parse(cartJSON);
  const wishlist: WishlistItemObject[] = JSON.parse(wishlistJSON);

  // Create a copy of the cart to avoid modifying the original directly
  const updatedCart: CartItemObject[] = [];

  ids.forEach((id) => {
    const itemToCopy = wishlist.find((wishlistItem: WishlistItemObject) => wishlistItem.id === id);

    if (itemToCopy && itemToCopy.id.length > 0) {
      const isItemInCart = updatedCart.some((cartItem) => cartItem.id === id);

      if (!isItemInCart) {
        const newCartItem: CartItemObject = {
          id: itemToCopy.id,
          name: itemToCopy.name,
          imageSrc: itemToCopy.imageSrc,
          price: itemToCopy.price,
          quantity: itemToCopy.quantity,
          inStock: itemToCopy.inStock,
          selectedVariantAttributes: itemToCopy.selectedVariantAttributes,
        };

        updatedCart.push(newCartItem);
      }
    }
  });

  const newUpdatedCart = [...updatedCart, ...cart];
  localStorage.setItem('app_wm_cart', JSON.stringify(newUpdatedCart));
  addToCartAnalytic(updatedCart);

  return updatedCart;
};

export const copyItemFromSharedToWishlist = (sharedItem: WishlistItemObject): WishlistItemObject[] => {
  const wishlistJSON = localStorage.getItem('app_wm_wishlist') || '[]';

  const wishlist: WishlistItemObject[] = JSON.parse(wishlistJSON);

  const isItemInWishlist = wishlist.some((wishlistItem) => wishlistItem.id === sharedItem.id);

  if (!isItemInWishlist) {
    const updatedWishlist = [...wishlist, sharedItem];
    localStorage.setItem('app_wm_wishlist', JSON.stringify(updatedWishlist));
    postWishlistMessageToIframe({
      type: 'updateWishlist',
      payload: updatedWishlist,
    });
    addToWishlistAnalytic([sharedItem]);
    return updatedWishlist;
  }

  return wishlist;
};

export const copyItemsFromSharedToWishlist = (sharedItems: WishlistItemObject[]): WishlistItemObject[] => {
  const wishlistJSON = localStorage.getItem('app_wm_wishlist') || '[]';
  const wishlist: WishlistItemObject[] = JSON.parse(wishlistJSON);

  const newItems: WishlistItemObject[] = sharedItems.filter(
    (sharedItem) => !wishlist.some((wishlistItem) => wishlistItem.id === sharedItem.id),
  );

  if (newItems.length > 0) {
    const updatedWishlist = [...wishlist, ...newItems];
    localStorage.setItem('app_wm_wishlist', JSON.stringify(updatedWishlist));
    postWishlistMessageToIframe({
      type: 'updateWishlist',
      payload: updatedWishlist,
    });
    addToWishlistAnalytic(newItems);
    return updatedWishlist;
  }

  return wishlist;
};

export const cutItemFromCartToShared = (id: string): CartItemObject[] => {
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';

  const cart: CartItemObject[] = JSON.parse(cartJSON);

  const isItemInCart = cart.some((cartItem) => cartItem.id === id);

  if (isItemInCart) {
    const updatedCart = cart.filter((cartItem: CartItemObject) => cartItem.id !== id);
    localStorage.setItem('app_wm_cart', JSON.stringify(updatedCart));
    postCartMessageToIframe({
      type: 'updateCart',
      payload: updatedCart,
    });
    return updatedCart;
  }

  return cart;
};

export const copyItemFromSharedToCart = (sharedItem: WishlistItemObject): CartItemObject[] => {
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';

  const cart: CartItemObject[] = JSON.parse(cartJSON);

  const isItemInCart = cart.some((cartItem) => cartItem.id === sharedItem.id);

  if (!isItemInCart) {
    const updatedCart = [...cart, sharedItem];
    localStorage.setItem('app_wm_cart', JSON.stringify(updatedCart));
    postCartMessageToIframe({
      type: 'updateCart',
      payload: updatedCart,
    });
    addToCartAnalytic([sharedItem]);
    return updatedCart;
  }

  return cart;
};

export const copyItemsFromSharedToCart = (sharedItems: WishlistItemObject[]): CartItemObject[] => {
  const cartJSON = localStorage.getItem('app_wm_cart') || '[]';
  const cart: CartItemObject[] = JSON.parse(cartJSON);

  const newItems: CartItemObject[] = sharedItems.filter(
    (sharedItem) => !cart.some((cartItem) => cartItem.id === sharedItem.id),
  );

  if (newItems.length > 0) {
    const updatedCart = [...cart, ...newItems];
    localStorage.setItem('app_wm_cart', JSON.stringify(updatedCart));
    postCartMessageToIframe({
      type: 'updateCart',
      payload: updatedCart,
    });
    addToCartAnalytic(newItems);
    return updatedCart;
  }

  return cart;
};

const addToWishlistAnalytic = (wishlistItems: WishlistItemObject[]) => {
  if (wishlistItems !== null) {
    const updatedWishlistItems: EcommerceItem[] = wishlistItems.map((wishlistItem: WishlistItemObject) => ({
      item_id: wishlistItem.id,
      item_name: wishlistItem.name,
      affiliation: '',
      coupon: '',
      discount: 0,
      index: 0,
      item_brand: '',
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: '',
      location_id: '',
      price: wishlistItem.price,
      quantity: wishlistItem.quantity,
    }));

    const totalPrice = wishlistItems.reduce((total: number, item: { price: number; quantity: number }) => {
      return total + item.price * item.quantity;
    }, 0);

    console.log({
      product_source: 'direct',
      ecommerce: {
        value: totalPrice ? totalPrice : 0,
        currency: 'USD',
        items: updatedWishlistItems ? updatedWishlistItems : [],
      },
    });

    dispatchAddToWishlist({
      product_source: 'direct',
      ecommerce: {
        value: totalPrice ? totalPrice : 0,
        currency: 'USD',
        items: updatedWishlistItems ? updatedWishlistItems : [],
      },
    });
  } else {
    dispatchAddToWishlist({
      product_source: 'direct',
      ecommerce: {
        value: 0,
        currency: 'USD',
        items: [],
      },
    });
  }
};

const addToCartAnalytic = (cartItems: CartItemObject[]) => {
  if (cartItems !== null) {
    const updatedCartItems: EcommerceItem[] = cartItems.map((cartItem: CartItemObject) => ({
      item_id: cartItem.id,
      item_name: cartItem.name,
      affiliation: '',
      coupon: '',
      discount: 0,
      index: 0,
      item_brand: '',
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: '',
      location_id: '',
      price: cartItem.price,
      quantity: cartItem.quantity,
    }));

    const totalPrice = cartItems.reduce((total: number, item: { price: number; quantity: number }) => {
      return total + item.price * item.quantity;
    }, 0);

    console.log({
      product_source: 'direct',
      ecommerce: {
        value: totalPrice ? totalPrice : 0,
        currency: 'USD',
        items: updatedCartItems ? updatedCartItems : [],
      },
    });

    dispatchAddToCartToAnalytics({
      product_source: 'direct',
      ecommerce: {
        value: totalPrice ? totalPrice : 0,
        currency: 'USD',
        items: updatedCartItems ? updatedCartItems : [],
      },
    });
  } else {
    dispatchAddToCartToAnalytics({
      product_source: 'direct',
      ecommerce: {
        value: 0,
        currency: 'USD',
        items: [],
      },
    });
  }
};
