import { ChakraProvider, ScaleFade, Spinner } from '@chakra-ui/react';
import { CustomTheme } from './theme/theme';
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { useFeedStore } from './store/feed';
import Overlay from './components/common/Overlay';
import { Helmet } from 'react-helmet';

const Shops = React.lazy(() => import('./pages/Shops'));
const Viewer = React.lazy(() => import('./pages/Viewer'));
const IframeGame = React.lazy(() => import('./components/IframeGame'));
const Home = React.lazy(() => import('./pages/Home'));
const MetaTagsComponent = React.lazy(() => import('./components/MetaTagsComponent'));

function App() {
  const { gameViewerActive, setGameViewerActive, setSelectedFeedItem } = useFeedStore();
  const isProd = process.env.REACT_APP_DOMAIN === 'https://walmartrealm.com';

  useEffect(() => {
    const closeGame = () => {
      setSelectedFeedItem({
        name: '',
        thumbnailUrl: '',
        type: '',
        additionalIdentifier: '',
        additionalUrl: '',
      });
      setGameViewerActive(false);
    };

    window.addEventListener('exitGame', closeGame);

    return () => {
      window.removeEventListener('exitGame', closeGame);
    };
  }, []);

  return (
    <ChakraProvider theme={CustomTheme}>
      {isProd && <MetaTagsComponent />}
      {!isProd && (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      <ScaleFade in={gameViewerActive} style={{ zIndex: 20 }}>
        <IframeGame onGameLoaded={() => setGameViewerActive(true)} />
      </ScaleFade>

      <HashRouter>
        <Overlay>
          <Suspense
            fallback={
              <Spinner color="black" size="lg" position="fixed" left={0} right={0} top={0} bottom={0} margin="auto" />
            }
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/feed" element={<Shops />} />
              <Route path="/viewer/:shopName/:sceneName" element={<Viewer />} />
              <Route path="/viewer/:productId" element={<Viewer />} />
              <Route path="/feed" element={<Shops />} />
              <Route path="*" element={<Navigate to="/feed" />} />
            </Routes>
          </Suspense>
        </Overlay>
      </HashRouter>
    </ChakraProvider>
  );
}

export default App;
