import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { CartItemObject, WishlistItemObject } from '../interfaces/components';

interface GlobalState {
  showOverlayTrue: boolean;
  bannerClosed: boolean;
  cartActive: boolean;
  wishlistActive: boolean;
  setBannerClosed: (state: boolean) => void;
  setShowOverlayTrue: (state: boolean) => void;
  setCartActive: (state: boolean) => void;
  setWishlistActive: (state: boolean) => void;
  // productTrailStartedId: string;
  // productDrawerLoading: boolean;
  // productDrawerData: ProductState;
  // setProductDrawerLoading: (state: boolean) => void;
  // setProductDrawerData: (state: ProductState) => void;
  // setProductIdTrailStartedId: (state: string) => void;
  cartItems: CartItemObject[];
  setCartItems: (items: CartItemObject[]) => void;
  wishlistItems: WishlistItemObject[];
  setWishlistItems: (items: WishlistItemObject[]) => void;
}

export const useGlobalStore = create<GlobalState>()(
  devtools(
    persist(
      (set) => ({
        showOverlayTrue: true,
        bannerClosed: false,
        cartActive: false,
        wishlistActive: false,
        // productTrailStartedId: '',
        // productDrawerLoading: false,
        // productDrawerData: {
        //   data: defaultProductData,
        //   active: false,
        //   productId: '',
        //   fromRecommendation: false,
        // },
        wishlistItems: [],
        cartItems: [],
        setBannerClosed: (state: boolean) => set(() => ({ bannerClosed: state })),
        setShowOverlayTrue: (state: boolean) => set(() => ({ showOverlayTrue: state })),
        setCartActive: (state: boolean) => set(() => ({ cartActive: state })),
        setWishlistActive: (state: boolean) => set(() => ({ wishlistActive: state })),
        // setProductIdTrailStartedId: (text: string) => set(() => ({ productTrailStartedId: text })),
        // setProductDrawerLoading: (state: boolean) => set(() => ({ productDrawerLoading: state })),
        // setProductDrawerData: (state: ProductState) => set(() => ({ productDrawerData: state })),
        setWishlistItems: (items: WishlistItemObject[]) => set(() => ({ wishlistItems: items })),
        setCartItems: (items: CartItemObject[]) => set(() => ({ cartItems: items })),
      }),
      {
        name: 'global-storage',
        partialize: (state) => ({ bannerClosed: state.bannerClosed, showOverlayTrue: state.showOverlayTrue }),
      },
    ),
  ),
);
