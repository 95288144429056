import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Close } from '../../Icons/Close';
import { dispatchPurchaseToAnalytics, retrieveEcommerceObjectFromWMCart } from '../../analytics';
import Emptycart from '../../assets/images/empty cart.webp';
import { CartItemObject, CommonProps, WishlistItemObject } from '../../interfaces/components';
import CartItem from './CartItem';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { onRemoveFromCart, updateCartItem } from '../../utils/cart';
import { moveToWishlist } from '../../utils/moving';

interface CartDrawerProps extends CommonProps {
  data: CartItemObject[];
  setCartItems: (data: CartItemObject[]) => void;
  setWishlistItems: (data: WishlistItemObject[]) => void;
  openWishlist: () => void;
}

function CartDrawer({ data, active, close, setCartItems, setWishlistItems, openWishlist }: CartDrawerProps) {
  const { height } = useWindowDimensions();
  const [cartSubtotal, setCartSubtotal] = useState<number>(0);
  const [tempWishlistIds, setTempWishlistIds] = useState<string[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const checkout = () => {
    const baseURL = `https://goto.walmart.com/c/5371324/568844/9383?veh=aff&sourceid=imp_000011112222333344&u=https%3A%2F%2Faffil.walmart.com%2Fcart%2FaddToCart%3Fitems%3D`;
    const cart = localStorage.getItem('wm_cart');
    if (cart !== null) {
      const idsWithQuantity = JSON.parse(cart).map((rec: CartItemObject) => rec.id + '_' + rec.quantity);
      const idsWithQuantityWithCommas = idsWithQuantity.join(',');
      const finalUrl = baseURL + idsWithQuantityWithCommas;
      dispatchPurchaseToAnalytics(retrieveEcommerceObjectFromWMCart());

      window.open(finalUrl, '_blank');
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const subTotal = data.reduce((total, item) => total + Number((item.price * item.quantity).toFixed(2)), 0);
      setCartSubtotal(subTotal);
    }
  }, [data]);

  useEffect(() => {
    if (tempWishlistIds.length > 0) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        console.log('Previous timer cleared.');
      }

      // Set a new timer
      console.log('new timer set');
      timerRef.current = setTimeout(() => {
        console.log('New timer finished!');
        // Clear the reference after the timer completes
        const { wishListItems, cartItems } = moveToWishlist(tempWishlistIds);
        setCartItems(cartItems);
        setWishlistItems(wishListItems);
        setTempWishlistIds([]);
        timerRef.current = null;
      }, 4000);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        console.log('Previous timer cleared.');
      }
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [tempWishlistIds]);

  return (
    <Drawer
      isOpen={active}
      placement="right"
      onClose={close}
      size={['full', 'full', 'sm', 'sm', 'sm']}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent maxH={height}>
        <Box
          boxSize={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="100"
          position="absolute"
          top="10px"
          right="10px"
          cursor="pointer"
          onClick={close}
        >
          <Close stroke="black" boxSize={[4, 4, 4]} />
        </Box>

        <DrawerBody p={['20px', '20px', '40px']}>
          {data.length == 0 ? (
            <Box
              w="100%"
              h="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={10}
            >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Image src={Emptycart} width={['100px', '100px', '150px']} />
                <Text
                  fontSize={['20px', '20px', '24px']}
                  fontFamily="Bogle"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  textAlign="center"
                >
                  {`Nothing to see here.`}
                </Text>
                <Text
                  fontSize={['20px', '20px', '24px']}
                  fontFamily="Bogle"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  textAlign="center"
                >
                  {`Let's fix that`}
                </Text>
              </Box>

              <Button
                variant="solid"
                color="white"
                padding={['12px']}
                borderRadius="40px"
                background="radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);"
                border="1px solid #FFFFFF4D"
                fontSize="18px"
                fontWeight="700"
                fontFamily="Bogle-Bold"
                _hover={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #007CF2 0%, #012D58 100%)' }}
                _active={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
                _focus={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
                cursor="pointer"
                minW="240px"
                height="50px"
                pointerEvents="auto"
                onClick={close}
              >
                Continue shopping
              </Button>
            </Box>
          ) : (
            <>
              <Text
                fontSize={['32px', '32px']}
                lineHeight={['42px', '42px']}
                fontFamily="Bogle"
                fontWeight="700"
                letterSpacing="-0.02em"
                textAlign="left"
              >
                Your cart
              </Text>

              <Box w="100%" h="auto" overflowY="auto" overflowX="hidden">
                {data.map((item: CartItemObject, index: number) => (
                  <Box key={item.id}>
                    <Collapse in={tempWishlistIds.some((id: string) => id === item.id)}>
                      {index === 0 && <Divider orientation="horizontal" borderColor="#C2C2C2" />}
                      <Box padding="24px 0px" display="flex" justifyContent="space-between" alignItems="center">
                        <Text
                          fontSize={['14px', '14px']}
                          fontFamily="Bogle"
                          fontWeight="700"
                          letterSpacing="-0.02em"
                          textAlign="left"
                        >
                          Saved to wishlist
                        </Text>
                        <Button
                          fontSize={['14px', '14px']}
                          fontFamily="Bogle"
                          fontWeight="500"
                          letterSpacing="-0.02em"
                          textAlign="left"
                          variant="link"
                          onClick={() => {
                            // Set a new timer
                            if (tempWishlistIds.length > 0) {
                              // Clear the reference after the timer completes
                              if (timerRef.current) {
                                clearTimeout(timerRef.current);
                              }
                              const { wishListItems, cartItems } = moveToWishlist(tempWishlistIds);
                              setCartItems(cartItems);
                              setWishlistItems(wishListItems);
                              setTempWishlistIds([]);
                              openWishlist();
                            }
                          }}
                          color="#0071CE"
                        >
                          view
                        </Button>
                      </Box>
                      <Divider orientation="horizontal" borderColor="#C2C2C2" />
                    </Collapse>

                    <Collapse in={!tempWishlistIds.some((id: string) => id === item.id)}>
                      <CartItem
                        {...item}
                        key={`${item.id}`}
                        cartItem={{
                          id: item.id,
                          name: item.name,
                          imageSrc: item.imageSrc,
                          price: item.price,
                          quantity: item.quantity,
                          inStock: item.inStock,
                          selectedVariantAttributes: item.selectedVariantAttributes,
                        }}
                        onItemRemove={() => {
                          const updatedCart = onRemoveFromCart(item.id);
                          setCartItems(updatedCart);
                        }}
                        updateCart={(id, newQuantity) => {
                          const updatedCart = updateCartItem(id, newQuantity);
                          setCartItems(updatedCart);
                        }}
                        addToWishlist={() => {
                          setTempWishlistIds([...tempWishlistIds, item.id]);
                        }}
                      />
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DrawerBody>
        {data.length > 0 && (
          <DrawerFooter p={['16px 20px 32px 20px']} pt={'0px'} bg="#E6F1FC" display="flex" flexDir="column" gap="3">
            <Box display="flex" justifyContent="space-between" w="100%" minH="40px">
              <Text fontSize={['18px', '18px']} fontFamily="Bogle" fontWeight="700" letterSpacing="-0.02em">
                Subtotal
              </Text>
              <Text fontSize={['18px', '18px']} fontFamily="Bogle" fontWeight="700" letterSpacing="-0.02em">
                ${cartSubtotal.toFixed(2)}
              </Text>
            </Box>
            <Button
              variant="solid"
              color="white"
              padding={['16px 32px']}
              borderRadius="40px"
              background="radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);"
              border="1px solid #FFFFFF4D"
              fontSize="14px"
              fontWeight="700"
              fontFamily="Bogle-Bold"
              _hover={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #007CF2 0%, #012D58 100%)' }}
              _active={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
              _focus={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
              transition="all 0.2s ease-in-out"
              cursor="pointer"
              w="100%"
              h="45px"
              onClick={checkout}
            >
              <Text
                fontSize={['18px', '18px']}
                lineHeight={['24px', '24px']}
                fontFamily="Bogle"
                fontWeight="700"
                letterSpacing="-0.02em"
              >
                Checkout on Walmart.com
              </Text>
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}

export default CartDrawer;
