import { dispatchAddToCartToAnalytics } from '../analytics';
import {
  CartItemObject,
  CartReturnObject,
  ProductData,
  ProductMedia,
  ProductVariant,
  SelectedVariantTypeState,
} from '../interfaces/components';

export const areAllInCart = (productIds: string[]): boolean => {
  const cart = localStorage.getItem('app_wm_cart');
  if (cart === null) {
    return false; // If the cart is empty, return false
  }

  const cartItems = JSON.parse(cart) as CartItemObject[];
  return productIds.every((productId) => cartItems.some((item) => item.id === productId));
};

export const isInCart = (productId: string): boolean => {
  const cart = localStorage.getItem('app_wm_cart');
  if (cart !== null) {
    const existsInCart = JSON.parse(cart).some((rec: CartItemObject) => rec.id === productId);
    return existsInCart;
  } else {
    return false;
  }
};

export const postCartMessageToIframe = (data: { type: string; payload: CartItemObject[] }) => {
  const iframe = document.getElementById('viewer-iframe') as HTMLIFrameElement;
  if (iframe && iframe.contentWindow) {
    iframe?.contentWindow.postMessage(data, '*');
  }
};

export const onParentCartChange = () => {
  const existingCart = localStorage.getItem('app_wm_cart') || '';
  if (existingCart && existingCart.length > 0) {
    postCartMessageToIframe({
      type: 'updateCart',
      payload: JSON.parse(existingCart),
    });
  } else {
    postCartMessageToIframe({
      type: 'updateCart',
      payload: [],
    });
  }
};

export const updateGlobalCart = (data: CartItemObject): CartReturnObject => {
  const returnObject: CartReturnObject = {
    countToAdd: 0,
    cartItems: [],
    itemsAddedToCart: false,
  };

  returnObject.countToAdd = data.quantity;

  const existingCart = localStorage.getItem('app_wm_cart');
  if (existingCart === null) {
    localStorage.setItem('app_wm_cart', JSON.stringify([data]));
    returnObject.cartItems = [data];
  } else {
    const _newCart = JSON.parse(existingCart);
    const index = _newCart.findIndex((rec: CartItemObject) => rec.id === data.id);
    if (index !== -1) {
      _newCart[index].quantity += data.quantity;
    } else {
      _newCart.push(data);
    }
    localStorage.setItem('app_wm_cart', JSON.stringify(_newCart));
    returnObject.cartItems = _newCart;
  }
  returnObject.itemsAddedToCart = true;

  return returnObject;
};

export const onAddToCart = (
  currentImages: ProductMedia[],
  selectedVariant: ProductVariant | undefined,
  productDrawerData: ProductData,
  count: number,
  selectedVariantAttributes: SelectedVariantTypeState,
  fromRecommendation: boolean,
): CartReturnObject => {
  const cartImage = currentImages.find((currentImage: ProductMedia) => currentImage.main);

  if (selectedVariant?.in_stock) {
    const item: CartItemObject = {
      id: selectedVariant?.variant_id ? selectedVariant?.variant_id : '',
      imageSrc: cartImage?.url || '',
      name: productDrawerData.title,
      quantity: count,
      price: productDrawerData.retail_price,
      inStock: selectedVariant?.in_stock || false,
      selectedVariantAttributes,
    };

    Object.keys(selectedVariantAttributes).forEach((key: string) => {
      const formattedKey = selectedVariantAttributes[parseInt(key)].type.toLowerCase();
      item[formattedKey] = selectedVariantAttributes[parseInt(key)].value;
    });
    dispatchAddToCartToAnalytics({
      ecommerce: {
        value: (item.quantity as number) * productDrawerData.retail_price,
        currency: 'USD',
        items: [
          {
            item_id: productDrawerData.parent_id,
            item_name: productDrawerData.title,
            affiliation: '',
            coupon: '',
            discount: 0,
            index: 0,
            item_brand: productDrawerData.brand,
            item_category: productDrawerData.item_category,
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_list_id: '',
            item_list_name: '',
            item_variant: selectedVariant?.variant_id ? selectedVariant?.variant_id : '',
            location_id: '',
            price: productDrawerData.retail_price,
            quantity: item.quantity as number,
          },
        ],
      },
      product_source: fromRecommendation ? 'recommended' : 'direct',
    });
    return updateGlobalCart(item);
  } else {
    return {
      countToAdd: 0,
      cartItems: [],
      itemsAddedToCart: false,
    };
  }
};

export const onRemoveFromCart = (id: string): CartItemObject[] => {
  const cart = localStorage.getItem('app_wm_cart');
  if (cart !== null) {
    const _newCart = JSON.parse(cart).filter((rec: CartItemObject) => rec.id !== id);
    localStorage.setItem('app_wm_cart', JSON.stringify(_newCart));
    return _newCart;
  } else {
    return [];
  }
};

export const updateCartItem = (id: string, newQuantity: number): CartItemObject[] => {
  const existingCart = localStorage.getItem('app_wm_cart') || '';

  if (existingCart?.length > 0 && existingCart) {
    const cart = JSON.parse(existingCart);
    const newCart = cart.map((cartItem: CartItemObject) =>
      cartItem.id === id ? { ...cartItem, quantity: newQuantity } : cartItem,
    );
    localStorage.setItem('app_wm_cart', JSON.stringify(newCart));
    return newCart;
  } else {
    return [];
  }
};
