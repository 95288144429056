import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Heart = (props: IconProps) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 20.9998C12 20.9998 2.25 15.7498 2.25 9.56226C2.25 8.2196 2.78337 6.93193 3.73277 5.98253C4.68217 5.03312 5.96984 4.49976 7.3125 4.49976C9.43031 4.49976 11.2444 5.65382 12 7.49976C12.7556 5.65382 14.5697 4.49976 16.6875 4.49976C18.0302 4.49976 19.3178 5.03312 20.2672 5.98253C21.2166 6.93193 21.75 8.2196 21.75 9.56226C21.75 15.7498 12 20.9998 12 20.9998Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
