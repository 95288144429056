import {
  dispatchAddToWishlistToAnalytics,
  dispatchShareWishlist,
  dispatchViewWishlist,
  EcommerceItem,
} from '../analytics';
import {
  WishlistReturnObject,
  ProductData,
  ProductMedia,
  ProductVariant,
  SelectedVariantTypeState,
  WishlistItemObject,
} from '../interfaces/components';

export const areAllInWishlist = (productIds: string[]): boolean => {
  const wishlist = localStorage.getItem('app_wm_wishlist');
  if (wishlist === null) {
    return false; // If the cart is empty, return false
  }

  const wishlistItems = JSON.parse(wishlist) as WishlistItemObject[];
  return productIds.every((productId) => wishlistItems.some((item) => item.id === productId));
};

export const isInWishlist = (productId: string): boolean => {
  const wishlist = localStorage.getItem('app_wm_wishlist');
  if (wishlist !== null) {
    const existsInWishlist = JSON.parse(wishlist).some((rec: WishlistItemObject) => rec.id === productId);
    return existsInWishlist;
  } else {
    return false;
  }
};

export const postWishlistMessageToIframe = (data: { type: string; payload?: WishlistItemObject[] }) => {
  const iframe = document.getElementById('viewer-iframe') as HTMLIFrameElement;
  if (iframe && iframe.contentWindow) {
    iframe?.contentWindow.postMessage(data, '*');
  }
};

export const openChildWishlist = () => {
  postWishlistMessageToIframe({
    type: 'openWishlist',
  });
};

export const onParentWishlistChange = () => {
  const existingWishlist = localStorage.getItem('app_wm_wishlist') || '';
  if (existingWishlist && existingWishlist.length > 0) {
    postWishlistMessageToIframe({
      type: 'updateWishlist',
      payload: JSON.parse(existingWishlist),
    });
  } else {
    postWishlistMessageToIframe({
      type: 'updateWishlist',
      payload: [],
    });
  }
};

export const updateGlobalWishlist = (data: WishlistItemObject): WishlistReturnObject => {
  const returnObject: WishlistReturnObject = {
    countToAdd: 0,
    wishlistItems: [],
    itemsAddedToWishlist: false,
  };

  returnObject.countToAdd = data.quantity;

  const existingWishlist = localStorage.getItem('app_wm_wishlist');
  if (existingWishlist === null) {
    localStorage.setItem('app_wm_wishlist', JSON.stringify([data]));
    returnObject.wishlistItems = [data];
  } else {
    const _newWishlist = JSON.parse(existingWishlist);
    const index = _newWishlist.findIndex((rec: WishlistItemObject) => rec.id === data.id);
    if (index !== -1) {
      _newWishlist[index].quantity += data.quantity;
    } else {
      _newWishlist.push(data);
    }
    localStorage.setItem('app_wm_wishlist', JSON.stringify(_newWishlist));
    returnObject.wishlistItems = _newWishlist;
  }

  returnObject.itemsAddedToWishlist = true;

  return returnObject;
};

export const onAddToWishlist = (
  currentImages: ProductMedia[],
  selectedVariant: ProductVariant | undefined,
  productDrawerData: ProductData,
  count: number,
  selectedVariantAttributes: SelectedVariantTypeState,
  fromRecommendation: boolean,
): WishlistReturnObject => {
  const wishListImage = currentImages.find((currentImage: ProductMedia) => currentImage.main);

  const item: WishlistItemObject = {
    id: selectedVariant?.variant_id ? selectedVariant?.variant_id : '',
    imageSrc: wishListImage?.url || '',
    name: productDrawerData.title,
    quantity: count,
    price: productDrawerData.retail_price,
    inStock: selectedVariant?.in_stock || false,
    selectedVariantAttributes,
  };

  Object.keys(selectedVariantAttributes).forEach((key: string) => {
    const formattedKey = selectedVariantAttributes[parseInt(key)].type.toLowerCase();
    item[formattedKey] = selectedVariantAttributes[parseInt(key)].value;
  });
  dispatchAddToWishlistToAnalytics({
    ecommerce: {
      value: (item.quantity as number) * productDrawerData.retail_price,
      currency: 'USD',
      items: [
        {
          item_id: productDrawerData.parent_id,
          item_name: productDrawerData.title,
          affiliation: '',
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: productDrawerData.brand,
          item_category: productDrawerData.item_category,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: selectedVariant?.variant_id ? selectedVariant?.variant_id : '',
          location_id: '',
          price: productDrawerData.retail_price,
          quantity: item.quantity as number,
        },
      ],
    },
    product_source: fromRecommendation ? 'recommended' : 'direct',
  });
  return updateGlobalWishlist(item);
};

export const onRemoveFromWishlist = (id: string): WishlistItemObject[] => {
  const wishlist = localStorage.getItem('app_wm_wishlist');
  if (wishlist !== null) {
    const _newWishlist = JSON.parse(wishlist).filter((rec: WishlistItemObject) => rec.id !== id);
    localStorage.setItem('app_wm_wishlist', JSON.stringify(_newWishlist));
    return _newWishlist;
  } else {
    return [];
  }
};

export const onRemoveMultipleFromWishlist = (ids: string[]): WishlistItemObject[] => {
  const wishlist = localStorage.getItem('app_wm_wishlist');
  if (wishlist !== null) {
    const _newWishlist = JSON.parse(wishlist).filter((rec: WishlistItemObject) => !ids.includes(rec.id));
    localStorage.setItem('app_wm_wishlist', JSON.stringify(_newWishlist));
    return _newWishlist;
  } else {
    return [];
  }
};

export const updateWishlistItem = (id: string, newQuantity: number): WishlistItemObject[] => {
  const existingWishList = localStorage.getItem('app_wm_wishlist') || '';

  if (existingWishList?.length > 0 && existingWishList) {
    const wishlist = JSON.parse(existingWishList);
    const newWishlist = wishlist.map((wishListItem: WishlistItemObject) =>
      wishListItem.id === id ? { ...wishListItem, quantity: newQuantity } : wishListItem,
    );
    localStorage.setItem('app_wm_wishlist', JSON.stringify(newWishlist));
    return newWishlist;
  } else {
    return [];
  }
};

export const shareWishListAnalytics = () => {
  const wishlist = localStorage.getItem('wm_wishlist');
  if (wishlist !== null) {
    const wishlistItems: EcommerceItem[] = JSON.parse(wishlist).map((wishlistItem: WishlistItemObject) => ({
      item_id: wishlistItem.id,
      item_name: wishlistItem.name,
      affiliation: '',
      coupon: '',
      discount: 0,
      index: 0,
      item_brand: '',
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: '',
      location_id: '',
      price: wishlistItem.price,
      quantity: wishlistItem.quantity,
    }));

    const totalPrice = wishlistItems.reduce((total: number, item: { price: number; quantity: number }) => {
      return total + item.price * item.quantity;
    }, 0);

    dispatchViewWishlist({
      product_source: 'direct',
      ecommerce: {
        value: totalPrice ? totalPrice : 0,
        currency: 'USD',
        items: wishlistItems ? wishlistItems : [],
      },
    });
  } else {
    dispatchViewWishlist({
      product_source: 'direct',
      ecommerce: {
        value: 0,
        currency: 'USD',
        items: [],
      },
    });
  }
};

export const shareWishListShareAnalytics = () => {
  const wishlist = localStorage.getItem('wm_wishlist');
  if (wishlist !== null) {
    const wishlistItems: EcommerceItem[] = JSON.parse(wishlist).map((wishlistItem: WishlistItemObject) => ({
      item_id: wishlistItem.id,
      item_name: wishlistItem.name,
      affiliation: '',
      coupon: '',
      discount: 0,
      index: 0,
      item_brand: '',
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: '',
      location_id: '',
      price: wishlistItem.price,
      quantity: wishlistItem.quantity,
    }));

    const totalPrice = wishlistItems.reduce((total: number, item: { price: number; quantity: number }) => {
      return total + item.price * item.quantity;
    }, 0);

    dispatchShareWishlist({
      product_source: 'direct',
      ecommerce: {
        value: totalPrice ? totalPrice : 0,
        currency: 'USD',
        items: wishlistItems ? wishlistItems : [],
      },
    });
  } else {
    dispatchShareWishlist({
      product_source: 'direct',
      ecommerce: {
        value: 0,
        currency: 'USD',
        items: [],
      },
    });
  }
};
