import { Box, Button, Divider, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { SelectedVariantTypeState, WishlistItemProps } from '../../interfaces/components';
import Counter from './Counter';
import { Trash } from '../../Icons/Trash';
import React from 'react';
import { debounce } from '../../utils';
import { CartPlus } from '../../Icons/CartPlus';
import { isInCart } from '../../utils/cart';
import { CrossIcon } from '../../Icons/CrossIcon';

function WishlistItemObject({
  isInStock,
  wishlistItem,
  onItemRemove,
  addToCart,
  removeFromCart,
  updateWishlist,
}: WishlistItemProps) {
  const { quantity, id, imageSrc, name, price, selectedVariantAttributes } = wishlistItem;
  const [productCount, setProductCount] = useState<number>(quantity);

  const debouncedUpdateWishlist = debounce(updateWishlist, 1000);

  const onIncrease = () => {
    setProductCount((state) => ++state);
    debouncedUpdateWishlist(id, productCount + 1);
  };

  const onDecrease = () => {
    if (productCount > 1) {
      setProductCount((state) => --state);
      debouncedUpdateWishlist(id, productCount - 1);
    }
  };

  return (
    <Box>
      <Box w="100%" h="auto" display="flex" flexDirection="column" gap="20px" key={id} padding="20px 0px">
        <Box display="flex" gap="3">
          <Image src={imageSrc} w="130px" h="auto" alt="product" objectFit="cover" />
          <Box display="flex" flexDirection="column" gap="10px">
            <Text
              fontSize={['14px', '14px']}
              lineHeight={['18px', '18px']}
              fontFamily="Bogle"
              fontWeight="700"
              letterSpacing="-0.02em"
              textAlign="left"
              color="#041F41;"
            >
              {name}
            </Text>

            {selectedVariantAttributes &&
              Object.keys(selectedVariantAttributes as SelectedVariantTypeState)?.length > 0 &&
              Object.keys(selectedVariantAttributes as SelectedVariantTypeState)?.map((key: string) => (
                <Box display="flex" gap="5px" key={key}>
                  <Text
                    fontSize={['14px', '14px']}
                    lineHeight={['18px', '18px']}
                    fontFamily="Bogle"
                    fontWeight="700"
                    letterSpacing="-0.02em"
                    textAlign="left"
                  >
                    {(selectedVariantAttributes as SelectedVariantTypeState)[parseInt(key)]?.type || 'Unknown Type'}:
                  </Text>
                  <Text
                    fontSize={['14px', '14px']}
                    lineHeight={['18px', '18px']}
                    fontFamily="Bogle"
                    fontWeight="400"
                    letterSpacing="-0.02em"
                    textAlign="left"
                  >
                    {(selectedVariantAttributes as SelectedVariantTypeState)[parseInt(key)]?.value || 'Unknown Value'}
                  </Text>
                </Box>
              ))}
          </Box>
        </Box>

        <Box display="flex" p="0px 10px" flexDirection="column" alignItems="flex-end" gap={2}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent={!wishlistItem.inStock || !isInCart(id) ? 'space-between' : 'flex-end'}
          >
            {!wishlistItem.inStock ||
              (!isInCart(id) && <Counter count={productCount} onIncrease={onIncrease} onDecrease={onDecrease} />)}

            <Text
              fontSize={['18px', '18px']}
              lineHeight={['24px', '24px']}
              fontFamily="Bogle"
              fontWeight="700"
              letterSpacing="-0.02em"
              textAlign="right"
            >
              ${(productCount * price).toFixed(2)}
            </Text>
          </Box>
          {!wishlistItem.inStock ? (
            <Button
              variant="solid"
              color="#2E2F32"
              padding={['8px 10px']}
              borderRadius="40px"
              background="#1A1A1A33"
              border="1px solid #FFFFFF4D"
              fontSize={['14px', '14px']}
              fontWeight="700"
              fontFamily="Bogle"
              cursor="pointer"
              pointerEvents="auto"
              h="32px"
              isDisabled={true}
            >
              Currently sold out
            </Button>
          ) : isInCart(id) ? (
            <Button
              leftIcon={<CrossIcon stroke="#2E2F32" boxSize={['12px']} />}
              variant="solid"
              color="#2E2F32"
              padding={['8px 10px']}
              borderRadius="40px"
              background="white"
              border="1px solid #9E9E9E"
              fontSize={['14px', '14px']}
              fontWeight="700"
              fontFamily="Bogle"
              cursor="pointer"
              pointerEvents="auto"
              h="32px"
              isDisabled={!isInStock || productCount === 0}
              onClick={removeFromCart}
            >
              Remove from cart
            </Button>
          ) : (
            <Button
              leftIcon={<CartPlus boxSize={['20px']} />}
              variant="solid"
              color="white"
              padding={['8px 10px']}
              borderRadius="40px"
              background="radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);"
              border="1px solid #FFFFFF4D"
              fontSize={['14px', '14px']}
              fontWeight="700"
              fontFamily="Bogle"
              _hover={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #007CF2 0%, #012D58 100%)' }}
              _active={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
              cursor="pointer"
              pointerEvents="auto"
              h="32px"
              isDisabled={!isInStock || productCount === 0}
              onClick={addToCart}
            >
              Add to cart
            </Button>
          )}

          <Button
            rightIcon={<Trash boxSize={[4, 4, 5]} />}
            variant="link"
            fontSize={['12px', '12px']}
            lineHeight={['16px', '16px']}
            fontFamily="Bogle"
            fontWeight="400"
            textAlign="right"
            color="#757575"
            onClick={onItemRemove}
          >
            Remove from wishlist
          </Button>
        </Box>
      </Box>

      <Divider orientation="horizontal" borderColor="#C2C2C2" />
    </Box>
  );
}

export default WishlistItemObject;
