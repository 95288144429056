import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Close } from '../../Icons/Close';
import EmptyWishlist from '../../assets/images/empty wishlist.png';
import { CartItemObject, CommonProps, WishlistItemObject } from '../../interfaces/components';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { onRemoveFromWishlist, shareWishListAnalytics, updateWishlistItem } from '../../utils/wishlist';
import WishlistItem from './WishlistItem';
import { copyItemFromWishlistToCart, copyMultipleItemsFromWishlistToCart } from '../../utils/moving';
import { CartPlus } from '../../Icons/CartPlus';
import { Share } from '../../Icons/Share';
import { areAllInCart, onRemoveFromCart } from '../../utils/cart';
import { useCustomToast } from './CustomToast';

interface WishlistDrawerProps extends CommonProps {
  data: WishlistItemObject[];
  setWishlistItems: (data: WishlistItemObject[]) => void;
  setCartItems: (data: CartItemObject[]) => void;
  shareWishlist: () => void;
}

function WishlistDrawer({ data, active, close, setWishlistItems, shareWishlist, setCartItems }: WishlistDrawerProps) {
  const { height } = useWindowDimensions();
  const [tempRemoveIds, setTempRemoveIds] = useState<string[]>([]);
  const timeoutIds = useRef<{ [key: string]: NodeJS.Timeout | null }>({});
  const showToast = useCustomToast();

  const addAllToCart = () => {
    const items = data.map((item: WishlistItemObject) => item.id);
    const updatedCart = copyMultipleItemsFromWishlistToCart(items);
    setCartItems(updatedCart);
    showToast({
      id: 'copyToCart',
      title: `${items.length} items added to your cart`,
      status: 'success',
      bottom: ['140px', '140px', '60px'],
    });
  };

  useEffect(() => {
    if (active) {
      shareWishListAnalytics();
    }
  }, [active]);

  return (
    <Drawer isOpen={active} placement="right" onClose={close} size={['full', 'sm', 'sm', 'sm', 'sm']} autoFocus={false}>
      <DrawerOverlay />
      <DrawerContent maxH={height}>
        <Box
          boxSize={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="100"
          position="absolute"
          top="10px"
          right="10px"
          cursor="pointer"
          onClick={close}
        >
          <Close stroke="black" boxSize={[4, 4, 4]} />
        </Box>

        <DrawerBody p={['20px', '20px', '40px']}>
          {data.length == 0 ? (
            <Box
              w="100%"
              h="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={10}
            >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Image src={EmptyWishlist} width={['100px', '100px', '150px']} />
                <Box display="flex" flexDirection="column" gap="4" alignItems="center">
                  <Text
                    fontSize={['20px', '20px', '24px']}
                    fontFamily="Bogle"
                    fontWeight="700"
                    letterSpacing="-0.02em"
                    textAlign="center"
                  >
                    This list is lonely
                  </Text>
                  <Text
                    width="80%"
                    fontSize={['12px', '12px', '16px']}
                    fontFamily="Bogle"
                    fontWeight="400"
                    letterSpacing="-0.02em"
                    textAlign="center"
                  >
                    Save items to your wishlist by tapping the heart icon while you shop.
                  </Text>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box display="flex" alignItems="center" gap="10px">
                <Text
                  fontSize={['32px', '32px']}
                  fontFamily="Bogle"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  textAlign="left"
                >
                  Wishlist
                </Text>
                <Text
                  fontSize={['18px', '18px']}
                  fontFamily="Bogle"
                  fontWeight="500"
                  letterSpacing="-0.02em"
                  textAlign="left"
                  color="#757575"
                >
                  {`(${data.length} items)`}
                </Text>
              </Box>

              <Box w="100%" h="auto" overflowY="auto" overflowX="hidden">
                {data.map((item: WishlistItemObject, index: number) => (
                  <Box key={item.id}>
                    <Collapse in={tempRemoveIds.some((id: string) => id === item.id)}>
                      {index === 0 && <Divider orientation="horizontal" borderColor="#C2C2C2" />}
                      <Box padding="24px 0px" display="flex" justifyContent="space-between" alignItems="center">
                        <Text
                          fontSize={['14px', '14px']}
                          fontFamily="Bogle"
                          fontWeight="700"
                          letterSpacing="-0.02em"
                          textAlign="left"
                        >
                          Removed from wishlist
                        </Text>
                        <Button
                          fontSize={['14px', '14px']}
                          fontFamily="Bogle"
                          fontWeight="500"
                          letterSpacing="-0.02em"
                          textAlign="left"
                          variant="link"
                          onClick={() => {
                            const timeoutId = timeoutIds.current[item.id];
                            if (timeoutId) {
                              const updatedTempRemoveIds = tempRemoveIds.filter((tempID: string) => tempID !== item.id);
                              setTempRemoveIds(updatedTempRemoveIds);
                              clearTimeout(timeoutId);
                              delete timeoutIds.current[item.id];
                            }
                          }}
                          color="#0071CE"
                        >
                          undo
                        </Button>
                      </Box>
                      <Divider orientation="horizontal" borderColor="#C2C2C2" />
                    </Collapse>
                    <Collapse in={!tempRemoveIds.some((id: string) => id === item.id)}>
                      <WishlistItem
                        isInStock={item.quantity > 0}
                        {...item}
                        key={`${item.id}`}
                        wishlistItem={{
                          id: item.id,
                          name: item.name,
                          imageSrc: item.imageSrc,
                          price: item.price,
                          quantity: item.quantity,
                          inStock: item.inStock,
                          selectedVariantAttributes: item.selectedVariantAttributes,
                        }}
                        onItemRemove={() => {
                          setTempRemoveIds([...tempRemoveIds, item.id]);

                          timeoutIds.current[item.id] = setTimeout(() => {
                            const updatedTempRemoveIds = tempRemoveIds.filter((tempID: string) => tempID !== item.id);
                            setTempRemoveIds(updatedTempRemoveIds);
                            const updatedWishlist = onRemoveFromWishlist(item.id);
                            setWishlistItems(updatedWishlist);
                            delete timeoutIds.current[item.id]; // Remove the timeout reference after deletion
                          }, 4000);
                        }}
                        updateWishlist={(id: string, newQuantity: number) => {
                          const updatedWishlist = updateWishlistItem(id, newQuantity);
                          setWishlistItems(updatedWishlist);
                        }}
                        addToCart={() => {
                          const updatedCart = copyItemFromWishlistToCart(item.id);
                          setCartItems(updatedCart);
                          showToast({
                            id: 'copyToCart',
                            title: `Item added to your cart`,
                            status: 'success',
                            bottom: ['140px', '140px', '60px'],
                          });
                        }}
                        removeFromCart={() => {
                          const updatedCart = onRemoveFromCart(item.id);
                          setCartItems(updatedCart);
                          showToast({
                            id: 'unCopyFromCart',
                            title: 'Item removed from your cart',
                            status: 'success',
                            bottom: ['140px', '140px', '60px'],
                          });
                        }}
                      />
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DrawerBody>
        {data.length > 0 && (
          <DrawerFooter p={['16px 20px 16px 20px']} pt={'0px'} bg="#E6F1FC" display="flex" flexDir="column" gap="3">
            <Button
              leftIcon={<CartPlus boxSize={['24px']} />}
              variant="solid"
              color="white"
              padding={['16px 32px']}
              borderRadius="40px"
              background="radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);"
              border="1px solid #FFFFFF4D"
              fontSize={['18px', '18px']}
              fontWeight="700"
              fontFamily="Bogle"
              _hover={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #007CF2 0%, #012D58 100%)' }}
              _active={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
              cursor="pointer"
              pointerEvents="auto"
              w="100%"
              h="44px"
              onClick={addAllToCart}
              isDisabled={areAllInCart(data.map((cartItem: CartItemObject) => cartItem.id) || [])}
            >
              Add all to cart
            </Button>
            <Button
              leftIcon={<Share stroke="currentcolor" boxSize={['24px']} />}
              variant="solid"
              color="#0071CE"
              padding={['16px 32px']}
              borderRadius="40px"
              background="transparent"
              border="1px solid #0071CE"
              fontSize={['18px', '18px']}
              fontWeight="700"
              fontFamily="Bogle-Bold"
              _hover={{
                color: 'white',
                background: 'radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);',
              }}
              _active={{
                color: 'white',
                background: 'radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);',
              }}
              transition="all 0.2s ease-in-out"
              cursor="pointer"
              w="100%"
              h="45px"
              onClick={shareWishlist}
            >
              Share wishlist
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}

export default WishlistDrawer;
